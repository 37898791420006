<template>
  <div>
    <!--    主体访客列表-->
    <div class="app-container">
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
        stripe
      >
        <el-col :span="3">
          <b>访客列表</b>
        </el-col>

        <!-- 访客姓名搜索框 -->
        <el-col :span="2" :push="4" > 访客姓名:  </el-col>
        <el-col :span="4" :push="1" >
          <el-input
            placeholder="请输入"
            v-model="searchVisitorName"
            size="small"
          >
          </el-input>
        </el-col>

        <el-col :span="6">
          <el-button size="mini" @click="clearInput">重置</el-button>
          <el-button size="mini" type="primary" @click="handleSearch" icon="el-icon-search">搜索</el-button>
        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        stripe
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background: '#eee', color: '#333' }"
      >
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="phone" label="手机"></el-table-column>
        <el-table-column prop="company" label="所在单位"></el-table-column>
        <el-table-column prop="postion" label="职务"></el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>
    </div>
  </div>
</template>

<script>

  import { request } from '@/network/network'

  export default {
    data() {
      return {
        loading: true,
        searchVisitorName:"",
        total: 0,
        pagesize: 3,
        currentPage: 2,
        currentData:'',  //每行的数据
        tableData:[]
      }
    },
    methods: {
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getAllLawyers(val)
      },
      // 重置搜索框
      clearInput() {
        this.searchLawyerName = "";
        this.searchFirmName = "";
        this.getAllVistors();
      },
      //搜索按钮
      handleSearch(){
        this.serchVisitor(this.searchVisitorName)
      },

      // 删除按钮
      handleDelete(index, row) {
        this.$confirm("是否要进行该删除操作?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.deleteVisitor(row.id)
              .then((res) => {
                this.$message({
                  message: "删除成功！",
                  type: "success",
                  duration: 1000,
                });
                this.getAllVistors();
              })
              .catch((err) => {
                this.$message({
                  message: "发生错误，删除失败！",
                  type: "error",
                  duration: 1000,
                });
                this.getAllVistors();
              });
          })
          .catch(() => {
            row.purchase = false;
            this.$message({
              message: "操作取消！",
              type: "info",
              duration: 1000,
            });
          });
      },

      getAllVistors(page){
        return request({
          url: '/visitor/visitors',
          method:'get',
          params:{
            page
          }
        }).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
          this.loading = false

        })
      },

      serchVisitor(name){
        return request({
          url: '/visitor/visitors',
          method:'get',
          params:{
            name
          }
        }).then(res => {
          this.total = res.data.total
          this.tableData = res.data.list

        })
      },

      deleteVisitor(id){
        return request({
          url: '/visitor/deleteVisitor',
          method: 'get',
          params: {
            id: id
          }
        })
      }
    },
    created() {
      this.getAllVistors();
    },
  };
</script>
<style scoped>
  .input-width {
    width: 80%;
  }
</style>
